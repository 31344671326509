<template>
  <div class="w-100 h-100 d-flex flex-column align-items-start justify-content-around">
      <div>本报名表所填信息正确无误，所提交的证件和照片真实有效，如有虚假，由此产生的一切后果由本人承担。</div>
      <div>报名人签名:</div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    readOnly: {
      default: false,
    },
    bound: String,
    form: Object,
    boundDict: {
      type: Array,
      default: function () {
        return ["value", "name"];
      },
    },
  },

  data() {
    return {
    };
  },
  methods: {
    
    updateData() {
    },
  },
  watch: {
    form: {
      deep: true,
      handler(form) {
        this.updateData();
      },
    },
  },
  mounted() {
  },
  created() {
    
  },
};
</script>

<style>
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
.listflex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.overflow {
  overflow-y: scroll;
}
</style>
