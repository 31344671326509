var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"outerCotent person-form"},[_c('div',{staticClass:"msgContent fs-xs enrollForm"},[_c('el-row',{staticClass:"d-flex",staticStyle:{"line-height":"314px","align-items":"stretch"}},[_c('el-col',{ref:"list1Container",staticStyle:{"flex":"5"}},[_c('draggable',{staticClass:"msgContent flexList fs-xs",attrs:{"list":_vm.list2,"group":"people","disabled":true}},_vm._l((_vm.list2),function(element){return _c('div',{key:element.sid,staticClass:"d-flex msgLabel canmove",class:[
                  'w' + element.zdbl,
                  (element.txlx == 'textarea' || element.txlx == 'shyj' || element.txlx == 'bmsm') ? 'msgtextarea' : '' ]},[_c('div',{staticClass:"msgName"},[(element.sfbt)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(element.zdmc)+" ")]),_c('div',{staticClass:"msgVal selectShow flexList",class:(element.canEdit ? 'canEdit ' : '') + element.zddm},[_c('yzFormItem',{attrs:{"innerElement":element,"examInfo":_vm.examInfo,"readOnly":element.canEdit
                        ? false
                        : element.readOnly
                        ? element.readOnly
                        : _vm.readOnly},on:{"change":_vm.getForm},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1)])}),0)],1),_c('el-col',{staticClass:"guding-img",staticStyle:{"flex":"1"}},[_c('img',{staticStyle:{"width":"120px","height":"160px","margin-top":"10px"},attrs:{"src":_vm.form.zpdz
                  ? _vm.img
                  : require('@/assets/images/person/person-none.png'),"alt":""}})])],1),_c('el-row',[_c('draggable',{staticClass:"msgContent flexList fs-xs",attrs:{"list":_vm.list3,"group":"people","disabled":true}},_vm._l((_vm.list3),function(element){return _c('div',{key:element.sid,staticClass:"d-flex msgLabel canmove",class:[
                'w' + element.zdbl,
                (element.txlx == 'textarea' || element.txlx == 'shyj' || element.txlx == 'bmsm') ? 'msgtextarea' : '',
                element.row ]},[_c('div',{staticClass:"msgName"},[(element.sfbt)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(element.zdmc)+" ")]),_c('div',{staticClass:"msgVal selectShow flexList",class:(element.canEdit ? 'canEdit ' : '') + element.zddm},[_c('yzFormItem',{attrs:{"innerElement":element,"examInfo":_vm.examInfo,"readOnly":element.canEdit
                      ? false
                      : element.readOnly
                      ? element.readOnly
                      : _vm.readOnly},on:{"change":_vm.getForm},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1)])}),0)],1),(!_vm.readOnly || _vm.kxgList.length != 0)?_c('div',{staticClass:"d-flex msgLabel w100 msgContent",staticStyle:{"height":"60px"}},[_c('div',{staticClass:"msgName"}),_c('div',{staticClass:"msgVal flexList"},[_c('button',{staticClass:"btn btn-info h35 mr-2 sub-btn",attrs:{"type":"button"},on:{"click":_vm.open}},[_vm._v(" 保存信息 ")])])]):_vm._e()],1)]),_c('el-dialog',{attrs:{"title":"提示","visible":_vm.dialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',[_vm._v(" 报名信息确认提交后无法修改，确认请点击“确认提交”，暂不提交请点击“保存并返回”。"),_c('br'),_c('span',{staticClass:"text-danger"},[_vm._v("注意：报名需“确认提交”才算报名成功。")])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.save}},[_vm._v("保存并返回")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitFrom}},[_vm._v("确认提交")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }