<template>
  <div v-if="show" class="w-100 h-100 d-flex align-items-center">
    <input
      v-model="form[element.bound]"
      :placeholder="readOnly ? '' : element.placeholder"
      @input="change"
      v-if="element.type == 'input' && !readOnly"
      class="form-control w-100 h30"
    />
    <el-date-picker
      v-model="form[element.bound]"
      type="date"
      :placeholder="element.placeholder"
      @input="change"
      value-format="yyyy-MM-dd"
      v-if="element.type == 'date' && !readOnly"
      class="w-100 h30"
    >
    </el-date-picker>
    <el-date-picker
            v-model="form[element.bound]"
            type="month"
            :placeholder="element.placeholder"
            @input="change"
            value-format="yyyy-MM"
            v-if="element.type == 'month' && !readOnly"
            class="w-100 h30"
    >
    </el-date-picker>

    <div
      v-if="
        element.type == 'textarea' &&
        element.bound != 'gzl' &&
        element.bound != 'xxjl' &&
        element.bound != 'jtcy' &&
        readOnly
      "
      class="form-control w-100 h-100"
      style="border:0;color: unset;background: unset;white-space: pre-wrap"
    >
      {{ form[element.bound] }}
    </div>
    <textarea
      v-model="form[element.bound]"
      rows="5"
      :placeholder="readOnly ? '' : element.placeholder"
      @input="change"
      :readonly="readOnly"
      v-if="
        element.type == 'textarea' &&
        element.bound != 'gzl' &&
        element.bound != 'xxjl' &&
        element.bound != 'jtcy' &&
        !readOnly
      "
      class="form-control w-100 h-100"
      style="border:0"
    />

    <div
      v-if="(element.type == 'input' || element.type == 'date' || element.type == 'month') && readOnly"
      class=""
    >
      {{ form[element.bound] }}
    </div>

    <yz-select
      :readOnly="readOnly"
      :dict="element.data"
      class="w-100"
      :bound="element.bound"
      v-model="form"
      @input="change"
      v-if="element.type == 'select' && element.dict != 'zgxlm'"
    ></yz-select>
    <yz-select-mulit
      :readOnly="readOnly"
      v-model="form"
      :dict="element.data"
      @input="change"
      :bounds="[
        ['ssdm', 'ssmc'],
        ['djsdm', 'djsmc'],
        ['qxdm', 'qxmc'],
      ]"
      v-if="element.type == 'local'"
    >
    </yz-select-mulit>
    <yz-select-mulit
      :readOnly="readOnly"
      v-model="form"
      :dict="element.data"
      @input="change"
      :bounds="[
        ['ssdm' + element.id, 'ssmc' + element.id],
        ['djsdm' + element.id, 'djsmc' + element.id],
        element.bound,
      ]"
      v-if="element.type == 'local1'"
    >
    </yz-select-mulit>

    <b-form-checkbox
      v-if="element.type == 'switch' && !readOnly"
      style="line-height: 25px"
      @input="change"
      v-model="form[element.bound]"
      switch
      class="switch-check"
    >
    </b-form-checkbox>
    <div v-if="element.type == 'switch' && readOnly">
      {{ form[element.bound] ? "是" : "否" }}
    </div>

    <yz-radio
      :dict="element.data"
      :bound="element.bound"
      :readOnly="readOnly"
      v-model="form"
      v-if="element.type == 'radio' && element.dict != 'zgxlm'"
      @update="change"
    ></yz-radio>

    <zgxl
      :dict="element.data"
      :bound="element.bound"
      :readOnly="readOnly"
      v-model="form"
      :examInfo="examInfo"
      v-if="element.dict == 'zgxlm'"
      @update="change"
      @refresh="refresh"
    ></zgxl>
    <gzcn
      v-model="form"
      :readOnly="readOnly"
      v-if="element.type == 'gzcn'"
    ></gzcn>
    <yzjl
      v-model="form"
      :bound="element.bound"
      :readOnly="readOnly"
      v-if="element.bound == 'gzl'"
    ></yzjl>
    <xxjl
      v-model="form"
      :bound="element.bound"
      :readOnly="readOnly"
      v-if="element.bound == 'xxjl'"
    ></xxjl>
    <jtcy
      v-model="form"
      :bound="element.bound"
      :readOnly="readOnly"
      v-if="element.bound == 'jtcy'"
    ></jtcy>
    <fileUpload
      v-model="form"
      :bound="element.bound"
      :readOnly="readOnly"
      :element="element"
      v-if="element.type == 'file'"
    ></fileUpload>
    <shyj
      v-model="form"
      :bound="element.bound"
      :readOnly="readOnly"
      v-if="element.type == 'shyj'"
    ></shyj>
    <bmsm
      v-model="form"
      :bound="element.bound"
      :readOnly="readOnly"
      v-if="element.type == 'bmsm'"
    ></bmsm>
  </div>
</template>

<script>
import YzSelect from "@/components/form/yzSelect.vue";
import YzRadio from "@/components/form/yzRadio.vue";
import YzSelectMulit from "@/components/form/yzSelectMulit.vue";
import zgxl from "@/components/form/zgxl.vue";
import gzcn from "@/components/form/gzcn.vue";
import yzjl from "@/components/form/yzjl.vue";
import xxjl from "@/components/form/xxjl.vue";
import jtcy from "@/components/form/jtcy.vue";
import fileUpload from "@/components/form/fileUpload.vue";
import shyj from "@/components/form/shyj.vue";
import bmsm from "@/components/form/bmsm.vue";
import { getDicts } from "@/api/common/dict";
import { covertToFormSingle } from "@/api/common/draggable";
export default {
  name: "yzFormItem.vue",
  components: {
    YzSelectMulit,
    YzSelect,
    YzRadio,
    zgxl,
    gzcn,
    yzjl,
    xxjl,
    jtcy,
    fileUpload,
    shyj,
    bmsm
  },
  model: {
    prop: "form",
    event: "input",
  },
  props: {
    readOnly: {
      default: false,
    },
    form: {
      default: {},
    },
    examInfo: {
      type: Object,
    },
    innerElement: {
      default: {},
    },
  },
  data() {
    return {
      element: {
        bound: "",
        type: "",
        data: [],
        name: "",
      },
      show: false,
    };
  },
  methods: {
    change() {
      this.$forceUpdate();
      this.$emit("input", this.form);
      this.$emit("change", this.form);
    },
    refresh() {
      let e = this.innerElement;
      this.element = covertToFormSingle(e);
      this.show = true;
      if (this.element.glzdb) {
        getDicts(this.element.dict).then((res) => {
          this.element.data = res[this.element.dict];
          this.$forceUpdate();
        });
      }
      if (this.element.zdkxz) {
        if (this.element.zdkxz.includes(",")) {
          let s1 = this.element.zdkxz.split(",")[0].split("|");
          let s2 = "";
          if (this.element.zdkxz.split(",")[1]) {
            s2 = this.element.zdkxz.split(",")[1].split("|");
          } else {
            s2 = this.element.zdkxz.split(",")[0].split("|");
          }

          this.element["data"] = [];
          for (let i in s1) {
            this.element.data.push({
              name: s2[i],
              value: s1[i],
            });
          }
        } else {
          let s1 = this.element.zdkxz.split("|");
          this.element["data"] = [];
          for (let i in s1) {
            this.element.data.push({
              name: s1[i],
              value: s1[i],
            });
          }
        }
        this.$forceUpdate();
      }
    },
  },
  watch: {
    innerElement: {
      deep: true,
      immediate: true,
      handler(e) {
        this.element = covertToFormSingle(e);
        this.show = true;
        if (this.element.glzdb) {
          getDicts(this.element.dict).then((res) => {
            this.element.data = res[this.element.dict];
            this.$forceUpdate();
          });
        }
        if (this.element.zdkxz) {
          if (this.element.zdkxz.includes(",")) {
            let s1 = this.element.zdkxz.split(",")[0].split("|");
            let s2 = "";
            if (this.element.zdkxz.split(",")[1]) {
              s2 = this.element.zdkxz.split(",")[1].split("|");
            } else {
              s2 = this.element.zdkxz.split(",")[0].split("|");
            }

            this.element["data"] = [];
            for (let i in s1) {
              this.element.data.push({
                name: s2[i],
                value: s1[i],
              });
            }
          } else {
            let s1 = this.element.zdkxz.split("|");
            this.element["data"] = [];
            for (let i in s1) {
              this.element.data.push({
                name: s1[i],
                value: s1[i],
              });
            }
          }
          this.$forceUpdate();
        }
      },
    },
  },
};
</script>

<style scoped></style>
